import React from 'react'
import ReactDOM from 'react-dom/client'
import { CookiesProvider } from 'react-cookie'
import '../src/i18n/configs'

import App from '../src/App'

const element = document.getElementById('root')
const root = ReactDOM.createRoot(element as HTMLElement)

root.render(
  <CookiesProvider>
    <App {...element?.dataset} />
  </CookiesProvider>
)
