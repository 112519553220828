import { Dispatch, SetStateAction } from 'react'
type Props = {
  setOpenMobileNav: Dispatch<SetStateAction<boolean>>
  isMobileNavOpen: boolean
}

const MobileNav = (props: Props) => {
  return (
    <div
      className="mobile-nav"
      onClick={() => {
        props.setOpenMobileNav(!props.isMobileNavOpen)
      }}
    >
      <div className="hamburger"></div>
    </div>
  )
}

export default MobileNav
