import { useState, useEffect } from 'react'
import i18n from 'i18next'
import { RecoilRoot } from 'recoil'
import { useCookies } from 'react-cookie'
import axios from 'axios'

import {
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
} from '@web3modal/ethers5/react'

import '../src/stylesheets/style.scss'

import SideNav from './components/SideNav'
import PlayArea from './components/PlayArea'
import ConnectArea from './components/ConnectArea'

import Routing from '../entrypoints/routing'

type Props = {
  page?: string
}

interface Window {
  location: Location
  navigator: {
    language: string
  }
}
declare let window: Window

interface WalletConnectEnableData {
  wallet_name: string
  wallet_id: string
  is_include_wallet: boolean
  is_featured_wallet: boolean
}

function App(props: Props) {
  const [web3, setWeb3] = useState({} as Web3)
  const [, setCookie, removeCookie] = useCookies()
  const [walletConnectEnableWallets, setWalletConnectEnableWallets] = useState<
    WalletConnectEnableData[]
  >([])
  const [isWeb3ModalInitialized, setIsWeb3ModalInitialized] = useState(false) // モーダルが初期化されたかどうかの状態

  useEffect(() => {
    const userLanguage = window.navigator.language
    if (userLanguage !== 'ja') {
      i18n.changeLanguage('en')
    } else {
      i18n.changeLanguage(userLanguage)
    }
    setCSRF()
    setAxios()
    fetchWalletConnectEnableWallets()
  }, [])

  useEffect(() => {
    if (walletConnectEnableWallets.length === 0) return

    if (!isWeb3ModalInitialized) {
      const projectId = 'ef091b8c0bbff7280c7a620b4f4e08c0' // WalletConnect Project ID

      const mainnet = {
        chainId: 1,
        name: 'Ethereum',
        currency: 'ETH',
        explorerUrl: 'https://etherscan.io',
        rpcUrl: 'https://cloudflare-eth.com',
      }

      const polygon = {
        chainId: 137,
        name: 'Polygon',
        currency: 'MATIC',
        explorerUrl: 'https://polygonscan.com/',
        rpcUrl: 'https://polygon-rpc.com/',
      }

      const astar = {
        chainId: 592,
        name: 'Astar Network Mainnet',
        currency: 'ASTR',
        explorerUrl: 'https://astar.subscan.io/',
        rpcUrl: 'https://evm.astar.network',
      }

      const metadata = {
        name: 'Sound Desert',
        description: 'Sound Desertは音楽NFTのための音楽プレイヤーです。',
        url: 'https://sound-desert.com',
        icons: [
          'https://d2tr0ey4qw5bcr.cloudfront.net/shared/apple-touch-icon.png',
        ],
      }

      createWeb3Modal({
        ethersConfig: defaultConfig({ metadata }),
        chains: [mainnet, polygon, astar],
        projectId,
        termsConditionsUrl: 'https://sound-desert.com/others/terms_fan',
        privacyPolicyUrl: 'https://sound-desert.com/others/privacy',
        includeWalletIds: walletConnectEnableWallets
          .filter((wallet) => wallet.is_include_wallet)
          .map((wallet) => wallet.wallet_id),
        featuredWalletIds: walletConnectEnableWallets
          .filter((wallet) => wallet.is_featured_wallet)
          .map((wallet) => wallet.wallet_id),
      })

      setIsWeb3ModalInitialized(true) // 初期化が完了したことを設定
    }
  }, [walletConnectEnableWallets])

  const setAxios = () => {
    axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
    axios.defaults.xsrfHeaderName = 'X-CSRF-Token'
    axios.defaults.withCredentials = false
  }

  const setCSRF = async () => {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content')
    if (!csrfToken) return

    removeCookie('CSRF-TOKEN', { path: '/' })
    setCookie('CSRF-TOKEN', csrfToken, {
      path: '/',
      secure: import.meta.env.VITE_STAGING === 'production',
      sameSite: 'lax',
    })
  }

  const fetchWalletConnectEnableWallets = async () => {
    const uri = `${
      import.meta.env.VITE_API_BASE_URI
    }/api/v1/wallet_connect/enable_wallets`
    const params = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    await fetch(uri, params).then(async (res) => {
      if (!res.ok) {
        return res.json().then((err) => Promise.reject(err))
      }
      res.json().then((data) => {
        const jsonResponseData = data as {
          wallet_connect_enable_wallets: WalletConnectEnableData[]
        }
        setWalletConnectEnableWallets(
          jsonResponseData.wallet_connect_enable_wallets
        )
      })
    })
  }

  return (
    <RecoilRoot>
      <SideNav />
      <main className="main">
        {isWeb3ModalInitialized && ( // モーダルが初期化されるまで待つ
          <>
            <ConnectArea setWeb3={setWeb3} />
            <Routing page={props.page} web3={web3} setWeb3={setWeb3} />
          </>
        )}
        <PlayArea />
      </main>
    </RecoilRoot>
  )
}

export default App
