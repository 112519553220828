import { useState, Dispatch, SetStateAction } from 'react'

import { useRecoilValue } from 'recoil'
import { walletState } from '../atoms/WalletAtom'
import BackButton from './BackButton'
import ConnectModal from './modal/connectModal'

type Props = {
  setWeb3: Dispatch<SetStateAction<Web3>>
}

function ConnectArea(props: Props) {
  const walletAddress = useRecoilValue(walletState)

  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <div className="connect-area">
      <div className="container">
        <BackButton />
        {walletAddress ? (
          <button
            className="connect-icon"
            onClick={() => setIsModalOpen(true)}
          ></button>
        ) : (
          <button
            className="connect-button"
            onClick={() => setIsModalOpen(true)}
          >
            ログインする
          </button>
        )}

        <ConnectModal
          setWeb3={props.setWeb3}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      </div>
    </div>
  )
}

export default ConnectArea
