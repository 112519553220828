import { useEffect, useState } from 'react'
import axios from 'axios'
import { useWeb3ModalProvider, useDisconnect } from '@web3modal/ethers5/react'

import { useRecoilState } from 'recoil'
import { walletState } from '../../atoms/WalletAtom'
import { emailState } from '../../atoms/EmailAtom'
import { artistDataState } from '../../atoms/artistDataAtom'
import { pageState } from '../../atoms/PageAtom'
import { sessionStatusState } from '../../atoms/SessionStatusAtom'
import { isLoginState } from '../../atoms/IsLoginAtom'

type Artist = {
  public_id: string
  display_name: string
  comment: string
  gift_detail: string
  icon_uri: string
}

const LogoutButton = () => {
  const [walletAddress, setWalletAddress] = useRecoilState(walletState)
  const [email, setEmail] = useRecoilState(emailState)
  const [isLogin, setIsLogin] = useRecoilState<boolean | null>(isLoginState)
  const [sessionStatus, setSessionStatus] =
    useRecoilState<SessionStatus>(sessionStatusState)
  const [, setArtistData] = useRecoilState<Artist>(artistDataState)
  const [, setPage] = useRecoilState(pageState)

  const [sessionNow, setSessionNow] = useState(false)

  const { disconnect } = useDisconnect()
  const { walletProvider } = useWeb3ModalProvider()

  useEffect(() => {
    if (!walletProvider && !walletAddress) return

    if (!walletProvider && walletAddress) {
      // logout(true)
      return
    }

    // アカウント切断を検出
    walletProvider.on('disconnect', (error: any, payload: any) => {
      logout(true)
    })
  }, [walletProvider])

  const logout = async (walletConnectDisconnected: boolean) => {
    if (sessionNow) return alert('処理中です')

    setSessionNow(true)

    await axios({
      method: 'DELETE',
      url: `${import.meta.env.VITE_API_BASE_URI}/api/v1/google_oauth/logout`,
    })
      .then(() => {
        setIsLogin(false)
        setSessionNow(false)
        setWalletAddress('')
        setEmail('')
        setSessionStatus({
          walletConnected: false,
          googleConnected: false,
          googleTokenValidityStatus: false,
        })
        setArtistData({} as Artist)
        setPage('home')
        if (!walletConnectDisconnected) disconnect()
        alert('ログアウトしました')
        window.location.reload()
      })
      .catch((error) => {
        console.error('error', error)
        setSessionNow(false)
      })
  }

  return (
    <button className="logout" onClick={() => logout(false)}>
      ログアウト
    </button>
  )
}

export default LogoutButton
