import { useState, useEffect, Dispatch, SetStateAction } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'
import { walletState } from '../../atoms/WalletAtom'
import { emailState } from '../../atoms/EmailAtom'
import { pageState } from '../../atoms/PageAtom'
import { pastPageState } from '../../atoms/PastPageAtom'
import { sessionStatusState } from '../../atoms/SessionStatusAtom'
import { isLoginState } from '../../atoms/IsLoginAtom'

import ConnectWalletButton from '../session/ConnectWallet'
import GoogleLoginButton from '../session/GoogleLogin'
import LogoutButton from '../session/Logout'

// import { ZERO_ADDRESS } from '../../constants'

type Props = {
  setWeb3: Dispatch<SetStateAction<Web3>>
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  isModalOpen: boolean
}

interface Window {
  location: Location
  navigator: {
    language: string
  }
}
declare let window: Window

function ConnectModal(props: Props) {
  const walletAddress = useRecoilValue(walletState)
  const email = useRecoilValue(emailState)
  const sessionStatus = useRecoilValue<SessionStatus>(sessionStatusState)
  const [page, setPage] = useRecoilState(pageState)
  const [, setPastPage] = useRecoilState(pastPageState)

  const [, setIsLogin] = useRecoilState<boolean | null>(isLoginState)

  const deepLink = `https://metamask.app.link/dapp/sound-desert.com${location.pathname}`

  useEffect(() => {
    setIsLogin(false)
  }, [])

  return (
    <div className={`modal bk connect-modal ${props.isModalOpen && 'show'}`}>
      <div
        className="overlay"
        onClick={() => {
          props.setIsModalOpen(false)
        }}
      ></div>

      <div className="modal-container">
        <div className="modal-ttl-section">
          <p className="modal-ttl color-white">
            {walletAddress ? 'ユーザーメニュー' : 'ログインメニュー'}
          </p>
        </div>

        {walletAddress ? (
          <>
            <button
              className="user-menu-link"
              onClick={() => {
                setPastPage(page)
                setPage('mypage')
                props.setIsModalOpen(false)
              }}
            >
              マイページを確認する
            </button>
            <button
              className="user-menu-link"
              onClick={() => {
                setPastPage(page)
                setPage('mymusic')
                props.setIsModalOpen(false)
              }}
            >
              マイミュージックを確認する
            </button>
            <hr className="mt1 mb1" />
          </>
        ) : (
          <p className="check-terms">
            <a
              href="/others/terms_fan"
              className="link"
              target="_blank"
              rel="noopener"
            >
              利用規約
            </a>
            および
            <a
              href="/others/privacy"
              className="link"
              target="_blank"
              rel="noopener"
            >
              プライバシーポリシー
            </a>
            に同意の上、サービスに接続します。
          </p>
        )}

        <div className="connect-button-area">
          <p className="connect-service-name">
            {sessionStatus.walletConnected
              ? 'ウォレットアドレス'
              : 'ウォレットで接続する'}
          </p>
          <ConnectWalletButton
            setWeb3={props.setWeb3}
            setIsModalOpen={props.setIsModalOpen}
          />
        </div>

        {!sessionStatus.googleConnected ? (
          <div className="connect-button-area">
            <p className="connect-service-name">
              {walletAddress
                ? 'Googleアカウントを連携する'
                : 'Googleアカウントで接続する'}
            </p>
            <GoogleLoginButton />
          </div>
        ) : (
          <div className="connect-button-area">
            <p className="connect-service-name">接続済みのGoogleアカウント</p>
            <p className="wallet-button">{email.split('@gmail.com')}</p>
          </div>
        )}

        {walletAddress && <LogoutButton />}

        <p className="check-terms">
          ログインおよび登録に関するお問い合わせは
          <button
            className="link"
            onClick={() => {
              setPastPage(page)
              setPage('contact')
              props.setIsModalOpen(false)
            }}
          >
            お問い合わせページ
          </button>
          からお送りください。
        </p>

        <p
          className="check-terms bold"
          style={{ fontSize: '.8rem', color: `var(--accent-color)` }}
        >
          ※モバイル版MetaMaskアプリからはGoogleログインが行えません。
          <br />
          連携をご希望の方はPC環境からご実行いただけますと幸いでございます。
          <br />
          <br />
          PC環境をお持ちでない方は、上記お問い合わせページからお送りください。
        </p>
      </div>
    </div>
  )
}

export default ConnectModal
