import { useState, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { pageState } from '../atoms/PageAtom'
import { pastPageState } from '../atoms/PastPageAtom'
import { walletState } from '../atoms/WalletAtom'
import { artistDataState } from '../atoms/artistDataAtom'
import { notificationsState } from '../atoms/NotificationsAtom'

import MobileNav from './nav/MobileNav'
import BuyCounter from './nav/BuyCounter'

const SideNav = () => {
  const [activeTab, setActiveTab] = useState('')
  const [, setPastPage] = useRecoilState(pastPageState)
  const [page, setPage] = useRecoilState(pageState)
  const notifications = useRecoilValue(notificationsState)
  const wallet = useRecoilValue(walletState)
  const artistData = useRecoilValue(artistDataState)

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  useEffect(() => {
    if (page === 'home') {
      setActiveTab('home')
    } else if (page === 'mymusic') {
      setActiveTab('mymusic')
    } else if (page.indexOf('playlist') !== -1) {
      setActiveTab('playlist')
    } else if (page === 'market') {
      setActiveTab('market')
    } else if (page.indexOf('artists') !== -1) {
      setActiveTab('artists')
    } else if (
      page === 'nft' ||
      page.indexOf('nft?') !== -1 ||
      page === 'nft/genres'
    ) {
      setActiveTab('nft')
    } else if (page.indexOf('generatives') !== -1) {
      setActiveTab('generatives')
    } else if (page.indexOf('gift') !== -1) {
      setActiveTab('gift')
    } else if (page.indexOf('tickets') !== -1) {
      setActiveTab('tickets')
    } else if (page.indexOf('others') !== -1) {
      setActiveTab('others')
    } else if (page.indexOf('mypage') !== -1) {
      setActiveTab('mypage')
    } else if (page.indexOf('artist_menu') !== -1) {
      setActiveTab('artist_menu')
    } else {
      setActiveTab('')
    }
  }, [page])

  return (
    <header className={`header ${isMobileNavOpen ? 'open' : ''}`}>
      <p
        className="logo"
        onClick={() => {
          setIsMobileNavOpen(false)
          setPastPage(page)
          setPage('home')
        }}
      >
        <picture>
          <source
            srcSet="https://d2tr0ey4qw5bcr.cloudfront.net/shared/logo/1row.webp"
            media="(max-width: 1024px)"
            type="image/webp"
          />
          <img
            src="https://d2tr0ey4qw5bcr.cloudfront.net/shared/logo/2row.webp"
            alt="Sound Desertロゴ"
          />
        </picture>
      </p>
      <MobileNav
        setOpenMobileNav={setIsMobileNavOpen}
        isMobileNavOpen={isMobileNavOpen}
      />
      <nav className="links">
        <button
          className={`${activeTab === 'home' ? 'active' : ''} link`}
          onClick={() => {
            setPastPage(page)
            setPage('home')
            setIsMobileNavOpen(false)
          }}
        >
          <span className="icon home"></span>
          Home
        </button>
        {wallet && (
          <button
            className={`${activeTab === 'mymusic' ? 'active' : ''} link`}
            onClick={() => {
              setPastPage(page)
              setPage('mymusic')
              setIsMobileNavOpen(false)
            }}
          >
            <span className="icon mymusic"></span>
            My music
          </button>
        )}
        {wallet && (
          <button
            className={`${activeTab === 'playlist' ? 'active' : ''} link`}
            onClick={() => {
              setPastPage(page)
              setPage('playlist')
              setIsMobileNavOpen(false)
            }}
          >
            <span className="icon playlist"></span>
            Playlist
          </button>
        )}
        <button
          className={`${activeTab === 'market' ? 'active' : ''} link`}
          onClick={() => {
            setPastPage(page)
            setPage('market')
            setIsMobileNavOpen(false)
          }}
        >
          <span className="icon market"></span>
          Market
        </button>

        <button
          className={`${activeTab === 'artists' ? 'active' : ''} link`}
          onClick={() => {
            setPastPage(page)
            setPage('artists')
            setIsMobileNavOpen(false)
          }}
        >
          <span className="icon artists"></span>
          Artists
        </button>

        <button
          className={`${activeTab === 'nft' ? 'active' : ''} link`}
          onClick={() => {
            setPastPage(page)
            setPage('nft')
            setIsMobileNavOpen(false)
          }}
        >
          <span className="icon nft"></span>
          ALL NFTs
        </button>

        <button
          className={`${activeTab === 'generatives' ? 'active' : ''} link`}
          onClick={() => {
            setPastPage(page)
            setPage('generatives')
            setIsMobileNavOpen(false)
          }}
        >
          <span className="icon generatives"></span>
          Projects
        </button>

        <button
          className={`${activeTab === 'collaborations' ? 'active' : ''} link`}
          onClick={() => {
            setPastPage(page)
            setPage('collaborations')
            setIsMobileNavOpen(false)
          }}
        >
          <span className="icon collaborations"></span>
          Collaboration
        </button>

        {wallet && (
          <button
            className={`${activeTab === 'gift' ? 'active' : ''} link`}
            onClick={() => {
              setPastPage(page)
              setPage('gift')
              setIsMobileNavOpen(false)
            }}
          >
            <span className="icon gift"></span>
            Gift
            {notifications > 0 && (
              <span className="notifications-count">
                {notifications > 99 ? '99+' : notifications}
              </span>
            )}
          </button>
        )}
        {wallet && (
          <button
            className={`${activeTab === 'tickets' ? 'active' : ''} link`}
            onClick={() => {
              setPastPage(page)
              setPage('tickets')
              setIsMobileNavOpen(false)
            }}
          >
            <span className="icon tickets"></span>
            Tickets
          </button>
        )}

        <div className="bottom">
          <button
            className={`${activeTab === 'others' ? 'active' : ''} link`}
            onClick={() => {
              setPastPage(page)
              setPage('others')
              setIsMobileNavOpen(false)
            }}
          >
            <span className="icon others"></span>
            Others
          </button>

          <button
            className="link"
            style={{
              background:
                'linear-gradient(rgba(39, 192, 161, 80%), rgba(232, 224, 51, 80%))',
              justifyContent: 'center',
              textShadow: '0 0 .3rem rgba(0,0,0,.5)',
              color: '#fff',
            }}
            onClick={() => {
              if (page !== 'collaborations/metame') {
                setPastPage(page)
                setPage('collaborations/metame')
              }
              setIsMobileNavOpen(false)
            }}
          >
            MetaMe
          </button>

          <button
            className="link"
            style={{
              background:
                'linear-gradient(rgba(230, 39, 77 ,0.5), rgba(230, 39, 77 , 1))',
              justifyContent: 'center',
              textShadow: '0 0 .3rem rgba(0,0,0,.5)',
              color: '#fff',
            }}
            onClick={() => {
              if (page !== 'collaborations/MONSTERbaSH') {
                setPastPage(page)
                setPage('collaborations/MONSTERbaSH')
              }
              setIsMobileNavOpen(false)
            }}
          >
            MONSTERbaSH
          </button>

          {wallet && (
            <button
              className={`${activeTab === 'mypage' ? 'active' : ''} link`}
              onClick={() => {
                setPastPage(page)
                setPage('mypage')
              }}
            >
              <span className="icon artist_menu"></span>
              Mypage
            </button>
          )}

          {artistData.public_id ? (
            <button
              className={`${activeTab === 'artist_menu' ? 'active' : ''} link`}
              onClick={() => {
                setPastPage(page)
                setPage('artist_menu')
                setIsMobileNavOpen(false)
              }}
            >
              <span className="icon artist_menu"></span>
              Artist Menu
            </button>
          ) : (
            ''
          )}

          {wallet !== '' && (
            <div
              onClick={() => {
                setPastPage(page)
                setPage('benefits')
                setIsMobileNavOpen(false)
              }}
            >
              <BuyCounter />
            </div>
          )}
        </div>
      </nav>
    </header>
  )
}

export default SideNav
